@import "../../assets/scss/theme.scss";

.select_options{
    margin-right: 10px;
    width: 200px;
    z-index: 1;
}
.input-border{
    border: 1px solid rgb(204, 204, 204) !important;
    height: 39px;
    width: 200px !important;
}
.img-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70px;
    width: 70px;
    border-radius: 3px;
}
.div-600-xs{
    width: 100% !important;
}